import { useEffect, useState } from 'react'

export default function useTimeout(timeoutMs?: number) {
  const [isRunning, setIsRunning] = useState(false)
  const time = timeoutMs ? timeoutMs : 200

  useEffect(() => {
    if (isRunning) {
      const timer = setTimeout(() => setIsRunning(false), time)

      return () => {
        clearTimeout(timer)
      }
    }
  }, [isRunning, time])

  return { isRunning, setIsRunning }
}
