import { useEffect, useRef } from 'react'
import { enableBodyScroll } from 'body-scroll-lock'
import { useRouter } from 'next/router'
import dayjs from 'dayjs'
import ReactTooltip from 'react-tooltip'
import type { Dispatch, RefObject } from 'react'
import type {
  ActionCreatorWithoutPayload,
  ThunkDispatch,
} from '@reduxjs/toolkit'

import { useSelect } from 'store/index'

import { useCertainPage } from 'hooks/useCertainPage'

import type { CalendarTooling } from './useCalendarTooling'
import { CalendarActions, CalendarState } from './useCalendarState'

import { errorCodeMap } from 'utils/Listings'

import { Availability } from 'types/externalData'

type UseCalendarEffects = (props: {
  availability: Availability | null | undefined
  appDispatch: ThunkDispatch<unknown, unknown, any>
  calendarContainerRef: RefObject<HTMLDivElement>
  closeDatePicker: ActionCreatorWithoutPayload<string>
  datePickerRef: RefObject<HTMLDivElement>
  disableFocusChange: boolean | undefined
  dispatch: Dispatch<any>
  endDate: CalendarState['endDate']
  fullScreenRef: RefObject<HTMLDivElement>
  getRangeAvailability: CalendarTooling['getRangeAvailability']
  getRange: CalendarTooling['getRange']
  handleApply: () => void
  handleClear: () => void
  isBookingFlow: boolean
  isMobile: boolean
  onApply: ((startDate: string, endDate: string) => void) | undefined
  isValidCheckIn: boolean
  startDate: CalendarState['startDate']
  setDatesErrorMsg?: (datesAreInvalid: string) => void
  tooltip: CalendarState['tooltip']
  tooltipRef: RefObject<HTMLDivElement>
}) => void

const getCalendarMountedPayload = (props) => {
  const {
    rangeSelectionAvailability,
    rawStartDate,
    rawEndDate,
    setDatesErrorMsg,
    thereIsNoAvailability,
  } = props
  const shouldSetQueryDates =
    thereIsNoAvailability || rangeSelectionAvailability.isValid
  const shouldSetInitialDates = !shouldSetQueryDates
  const hasDates = !!(rawStartDate && rawEndDate)
  if (shouldSetQueryDates) {
    return {
      startDate: rawStartDate,
      visibleStartDate: rawStartDate,
      endDate: rawEndDate,
      visibleEndDate: rawEndDate,
    }
  }

  if (shouldSetInitialDates) {
    if (hasDates) setDatesErrorMsg?.(errorCodeMap['missingDates'])

    return {
      startDate: '',
      visibleStartDate: '',
      endDate: '',
      visibleEndDate: '',
    }
  }
}

const useCalendarEffects: UseCalendarEffects = (props) => {
  const {
    appDispatch,
    availability,
    calendarContainerRef,
    closeDatePicker,
    datePickerRef,
    disableFocusChange,
    dispatch,
    endDate,
    fullScreenRef,
    getRangeAvailability,
    handleClear,
    isBookingFlow,
    isMobile,
    onApply,
    setDatesErrorMsg,
    startDate,
    tooltip,
    tooltipRef,
  } = props
  const router = useRouter()
  const mountedRef = useRef(false)
  const thereIsNoAvailability = !availability?.length
  const isSearchPage = useCertainPage(/vacation-rentals\/search/)
  const bookingMountedRef = useRef(false)
  const selectedDates = useSelect((state) => state.search.selectedDates)

  useEffect(() => {
    if (isMobile) return

    let shouldClosePicker = false
    const handleOutsideClick = (e: MouseEvent) => {
      e.stopPropagation()

      if (
        !disableFocusChange &&
        calendarContainerRef.current &&
        datePickerRef.current &&
        !calendarContainerRef.current.contains(e.target as Node) &&
        !datePickerRef.current.contains(e.target as Node)
      ) {
        fullScreenRef.current && enableBodyScroll(fullScreenRef.current)
        shouldClosePicker = true
        if (startDate && endDate) {
          onApply?.(startDate, endDate)
        } else {
          handleClear()
        }
        appDispatch(closeDatePicker())
      }
    }

    document.addEventListener('click', handleOutsideClick)

    return () => {
      document.removeEventListener('click', handleOutsideClick)
      if (shouldClosePicker) {
        appDispatch(closeDatePicker())
      }
    }
  }, [
    appDispatch,
    calendarContainerRef,
    datePickerRef,
    disableFocusChange,
    endDate,
    fullScreenRef,
    handleClear,
    isMobile,
    onApply,
    startDate,
    closeDatePicker,
  ])

  useEffect(() => {
    if (!isBookingFlow || thereIsNoAvailability || bookingMountedRef.current)
      return

    const rangeSelectionAvailability = getRangeAvailability(
      dayjs(router.query.startDate as string),
      dayjs(router.query.endDate as string),
    )
    const [rawStartDate, rawEndDate] = [
      router.query.startDate,
      router.query.endDate,
    ]
    const payload = getCalendarMountedPayload({
      rangeSelectionAvailability,
      availability,
      rawStartDate,
      rawEndDate,
      setDatesErrorMsg,
      thereIsNoAvailability,
    })

    dispatch({
      type: CalendarActions['SET_RANGE_SELECTION'],
      payload,
    })

    bookingMountedRef.current = true
  }, [
    availability,
    thereIsNoAvailability,
    dispatch,
    isBookingFlow,
    router.query.endDate,
    router.query.startDate,
    setDatesErrorMsg,
    getRangeAvailability,
  ])

  useEffect(() => {
    if (isMobile && tooltip.show) {
      ReactTooltip.show(tooltipRef.current!)
    }
  })

  useEffect(() => {
    mountedRef.current = true
    return () => {
      mountedRef.current = false
    }
  }, [])

  useEffect(() => {
    if (!mountedRef.current || !isSearchPage) return
    if (
      (selectedDates?.start && selectedDates?.end) ||
      (router.query?.startDate && router.query?.endDate)
    )
      return

    dispatch({
      type: CalendarActions['RESET_RANGE_SELECTION'],
      payload: { calendarKey: dayjs().format('SSS') },
    })
  }, [selectedDates?.start, selectedDates?.end, dispatch, isSearchPage, router])
}

export { useCalendarEffects }
