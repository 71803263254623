import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock'
import { DateCallback, OnChangeDateCallback } from 'react-calendar'
import dayjs from 'dayjs'
import type { Dayjs } from 'dayjs'
import type { Dispatch, MutableRefObject, RefObject } from 'react'
import type {
  ActionCreatorWithoutPayload,
  ThunkDispatch,
} from '@reduxjs/toolkit'

import useAnalytics from 'hooks/useAnalytics'

import type { CalendarTooling } from './useCalendarTooling'
import { CalendarActions } from './useCalendarState'

import availabilityCodes from 'constants/availableCodes'
import DatesConstants from 'constants/dates'

import type { Availability, MinStay } from 'types/externalData'

export type DatesEventHandlers = {
  handleApply: () => void
  handleCalendarChange: OnChangeDateCallback
  handleClear: () => void
  handleDatePickerClick: (
    e: React.MouseEvent<HTMLInputElement | HTMLButtonElement>,
  ) => void
  handleCancel: () => void
  handleCalendarRangeSelection: (
    selectionStartDate: Dayjs,
    selectionEndDate: Dayjs,
  ) => void
  handleCalendarSingleSelection: (selectionStartDate: Dayjs) => void
  handleCalendarClickDay: DateCallback
}

type UseCalendarEvents = (props: {
  appDispatch: ThunkDispatch<unknown, unknown, any>
  availability: Availability | null | undefined
  closeDatePicker: ActionCreatorWithoutPayload
  directionRef: MutableRefObject<'ltr' | 'rtl'>
  dispatch: Dispatch<any>
  endDate: string
  fullScreenRef: RefObject<HTMLDivElement>
  getBehavior: CalendarTooling['getBehavior']
  getRangeAvailability: CalendarTooling['getRangeAvailability']
  getSingleAvailability: CalendarTooling['getSingleAvailability']
  isBookingFlow: boolean
  isDesktop: boolean
  listingId?: string
  minStay: MinStay | null | undefined
  onApply?: (startDate: string, endDate: string) => void
  onChange?: (startDate: string, endDate: string) => void
  onClearDates?: () => void
  openDatePicker: ActionCreatorWithoutPayload
  setDatesErrorMsg?: (datesAreInvalid: string) => void
  startDate: string
}) => Omit<
  DatesEventHandlers,
  'handleCalendarRangeSelection' | 'handleCalendarSingleSelection'
>

const useCalendarEvents: UseCalendarEvents = (props) => {
  const {
    appDispatch,
    availability,
    closeDatePicker,
    directionRef,
    dispatch,
    endDate,
    fullScreenRef,
    getBehavior,
    getRangeAvailability,
    getSingleAvailability,
    isBookingFlow,
    isDesktop,
    listingId,
    onApply,
    onChange,
    onClearDates,
    openDatePicker,
    setDatesErrorMsg,
    startDate,
  } = props

  const { clickProductSection } = useAnalytics()
  const handleApply = (nextStartDate = startDate, nextEndDate = endDate) => {
    onApply?.(nextStartDate, nextEndDate)
    appDispatch(closeDatePicker())
    if (fullScreenRef.current) {
      enableBodyScroll(fullScreenRef.current)
    }
  }
  const handleCalendarRangeSelection: DatesEventHandlers['handleCalendarRangeSelection'] =
    (selectionStartDate, selectionEndDate) => {
      if (!selectionEndDate) return

      const rangeSelectionAvailability = getRangeAvailability(
        selectionStartDate,
        selectionEndDate,
      )
      const calendarRangePayload = getCalendarRangePayload({
        selectionStartDate,
        selectionEndDate,
        rangeSelectionAvailability,
      })

      if (!rangeSelectionAvailability.isValid) {
        dispatch({
          type: CalendarActions['INVALIDATE_RANGE_SELECTION'],
          payload: calendarRangePayload,
        })
      } else {
        dispatch({
          type: CalendarActions['SET_RANGE_SELECTION'],
          payload: calendarRangePayload,
        })

        setDatesErrorMsg?.('')

        if (isDesktop) {
          handleApply(
            calendarRangePayload.startDate,
            calendarRangePayload.endDate,
          )
        }
      }

      if (!isBookingFlow) return
      clickProductSection({
        listingId: listingId!,
        section: 'Listing Calendar',
        clicked: 'Check-out',
        response: rangeSelectionAvailability.isValid
          ? null
          : rangeSelectionAvailability.reason.label,
      })
    }

  const getCalendarRangePayload = (props) => {
    const { selectionStartDate, selectionEndDate, rangeSelectionAvailability } =
      props
    const { isValid, reason, stayOnlyInfo } = rangeSelectionAvailability
    const nextStartDate = selectionStartDate.format(DatesConstants['DEFAULT'])
    const nextEndDate = selectionEndDate.format(DatesConstants['DEFAULT'])

    if (!isValid && isBookingFlow) {
      if (reason.type === 'CHECK_IN_NOT_AVAILABLE') {
        return {
          startDate: '',
          visibleStartDate: '',
        }
      }

      if (reason.type === 'CHECK_OUT_AT_PAST') {
        return {
          startDate: nextEndDate,
          visibleStartDate: nextEndDate,
          tooltip: {
            type: rangeSelectionAvailability.reason.type,
            label: rangeSelectionAvailability.reason.label,
            show: true,
            date: nextStartDate,
          },
        }
      }

      if (reason.type === 'CHECK_OUT_SAME_AS_CHECK_IN') {
        return {
          startDate: nextStartDate,
          visibleStartDate: nextStartDate,
          tooltip: {
            type: rangeSelectionAvailability.reason.type,
            label: rangeSelectionAvailability.reason.label,
            show: true,
            date: nextStartDate,
          },
        }
      }

      return {
        tooltip: {
          type: rangeSelectionAvailability.reason.type,
          label: rangeSelectionAvailability.reason.label,
          show: true,
          date: nextEndDate,
        },
        startDate: nextStartDate,
        visibleStartDate: nextStartDate,
        stayOnlyInfo,
      }
    } else {
      return {
        startDate: nextStartDate,
        visibleStartDate: nextStartDate,
        endDate: nextEndDate,
        visibleEndDate: nextEndDate,
        stayOnlyInfo,
      }
    }
  }

  const handleCalendarSingleSelection: DatesEventHandlers['handleCalendarSingleSelection'] =
    (selectionStartDate) => {
      if (!isBookingFlow) return
      const singleSelectionAvailability =
        getSingleAvailability(selectionStartDate)

      const { stayOnlyInfo } = singleSelectionAvailability

      if (!singleSelectionAvailability.isValid) {
        dispatch({
          type: CalendarActions['INVALIDATE_SINGLE_SELECTION'],
          payload: {
            tooltip: {
              type: singleSelectionAvailability.reason.type,
              label: singleSelectionAvailability.reason.label,
              show: true,
              date: selectionStartDate.format(DatesConstants.DEFAULT),
            },
            stayOnlyInfo,
            isValidCheckIn: false,
            calendarKey: dayjs().format('SSS'),
            visibleStartDate: '',
            startDate: '',
            endDate: '',
          },
        })
      } else {
        dispatch({
          type: CalendarActions['SET_REACHABLE_DATES'],
          payload: {
            reachableDates: singleSelectionAvailability.reachableDates,
            stayOnlyInfo,
          },
        })
      }
      clickProductSection({
        listingId: listingId!,
        section: 'Listing Calendar',
        clicked: 'Check-in',
        response: singleSelectionAvailability.isValid
          ? null
          : singleSelectionAvailability.reason.label,
      })
    }

  const handleCalendarChange: DatesEventHandlers['handleCalendarChange'] = (
    dates,
  ) => {
    const selectionIsRangeMode = 'length' in dates
    if (!selectionIsRangeMode) return
    const [rawStartDate, rawEndDate] = dates
    const selectionStartDate = dayjs(rawStartDate)
    const selectionEndDate = rawEndDate ? dayjs(rawEndDate) : null
    const { isSingleSelection, isRangeSelection } = getBehavior(
      selectionStartDate,
      selectionEndDate,
    )

    const nextStartDate = selectionStartDate.format(DatesConstants['DEFAULT'])
    const nextEndDate =
      selectionEndDate && !selectionStartDate.isSame(selectionEndDate, 'day')
        ? selectionEndDate.format(DatesConstants['DEFAULT'])
        : ''

    dispatch({
      type: CalendarActions['SET_RANGE_SELECTION'],
      payload: {
        startDate: nextStartDate,
        endDate: nextEndDate,
        visibleStartDate: selectionStartDate.format(DatesConstants['DEFAULT']),
      },
    })

    if (isSingleSelection) {
      handleCalendarSingleSelection(selectionStartDate)
    }

    if (isRangeSelection) {
      handleCalendarRangeSelection(selectionStartDate, selectionEndDate!)
    }

    if (!availability && isRangeSelection) {
      onChange?.(
        dayjs(selectionStartDate).format(DatesConstants.DEFAULT),
        selectionEndDate
          ? dayjs(selectionEndDate).format(DatesConstants.DEFAULT)
          : '',
      )
    }
  }

  const handleCalendarClickDay: DatesEventHandlers['handleCalendarClickDay'] = (
    currentDate,
    evt,
  ) => {
    evt.stopPropagation()
    const hasNotStartDate = !startDate
    const hasEndDate = !!endDate

    if (hasNotStartDate || hasEndDate) return

    const checkOutDayIsAtPast = dayjs(startDate).isAfter(dayjs(currentDate))
    directionRef.current = checkOutDayIsAtPast ? 'rtl' : 'ltr'
  }

  const handleClear: DatesEventHandlers['handleClear'] = () => {
    onClearDates?.()
    dispatch({
      type: CalendarActions['RESET_RANGE_SELECTION'],
      payload: { calendarKey: dayjs().format('SSS') },
    })
  }

  const handleDatePickerClick: DatesEventHandlers['handleDatePickerClick'] = (
    e,
  ) => {
    e.stopPropagation()
    appDispatch(openDatePicker())
    if (isDesktop || !fullScreenRef.current) return
    disableBodyScroll(fullScreenRef.current)
  }

  const handleCancel: DatesEventHandlers['handleCancel'] = () => {
    appDispatch(closeDatePicker())
    fullScreenRef.current && enableBodyScroll(fullScreenRef.current)
  }

  return {
    handleApply,
    handleCalendarChange,
    handleCancel,
    handleCalendarClickDay,
    handleClear,
    handleDatePickerClick,
  }
}

export { useCalendarEvents }
