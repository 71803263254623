import { useRef } from 'react'

import { useDispatch, useSelect } from 'store/index'

import { openDatePicker, closeDatePicker } from 'reducers/uiState'

import { uiState } from 'selectors/index'

import { useCertainPage } from 'hooks/useCertainPage'

import { useCalendarTooling } from './useCalendarTooling'
import { useCalendarEvents } from './useCalendarEvents'
import { useCalendarCustomizers } from './useCalendarCustomizers'
import { CalendarActions, useCalendarState } from './useCalendarState'
import { useCalendarEffects } from './useCalendarEffects'
import { useCalendarMemos } from './useCalendarMemos'

import type { Availability, MinStay } from 'types/externalData'

export type UseCalendarProps = {
  availability?: Availability | null
  disableFocusChange?: boolean
  initialEndDate?: string
  initialStartDate?: string
  listingId?: string
  minStay?: MinStay | null
  onApply?: (startDate: string, endDate: string) => void
  onChange?: (startDate: string, endDate: string) => void
  onClearDates?: () => void
  setDatesErrorMsg?: (datesAreInvalid: string) => void
  small?: boolean | undefined
}

const useCalendar = (props: UseCalendarProps) => {
  const {
    availability,
    disableFocusChange,
    initialEndDate = '',
    initialStartDate = '',
    listingId,
    minStay,
    onApply,
    onChange,
    onClearDates,
    setDatesErrorMsg,
    small,
  } = props
  const calendarContainerRef = useRef<HTMLDivElement>(null)
  const calendarDialogRef = useRef<HTMLDivElement>(null)
  const datePickerRef = useRef<HTMLDivElement>(null)
  const fullScreenRef = useRef<HTMLDivElement>(null)
  const tooltipRef = useRef<HTMLDivElement>(null)
  const directionRef = useRef<'ltr' | 'rtl'>('ltr')
  const isSearchPage = useCertainPage(/vacation-rentals\/search/)
  const isBookingFlow = useCertainPage(
    /vacation-rentals.*\/[0-9]/,
    /booking.*\/summary/,
    /booking.*\/payment-details/,
  )

  const [state, dispatch] = useCalendarState({
    initialStartDate,
    initialEndDate,
    isSearchPage,
    isBookingFlow,
  })
  const {
    activeStartDate,
    calendarKey,
    endDate,
    isValidCheckIn,
    stayOnlyInfo,
    startDate,
    tooltip,
    visibleEndDate,
    visibleStartDate,
  } = state
  const {
    availabilityObj,
    formattedStartDate,
    formattedEndDate,
    combinedDate,
  } = useCalendarMemos({ availability, visibleStartDate, visibleEndDate })
  const calendarIsBlocked = tooltip.show || !startDate || !endDate
  const appDispatch = useDispatch()
  const width = useSelect((state) => state.uiState.width)
  const calendarOpen = useSelect((state) => state.uiState.datePickerFocused)
  const isMobile = useSelect(uiState.selectIsMobile)
  const isDesktop = !isMobile

  const setActiveStartDate = (activeStartDate) =>
    dispatch({
      type: CalendarActions['SET_ACTIVE_START_DATE'],
      payload: { activeStartDate },
    })

  const setStartDate = (startDate) =>
    dispatch({
      type: CalendarActions['SET_START_DATE'],
      payload: {
        startDate,
      },
    })

  const { getRange, getSingleAvailability, getRangeAvailability, getBehavior } =
    useCalendarTooling({
      availability,
      availabilityObj,
      calendarOpen,
      directionRef,
      isValidCheckIn,
      minStay,
      calendarState: state,
    })

  const {
    getFormatShortWeekday,
    getTileClassName,
    getTileContent,
    getTileDisabled,
  } = useCalendarCustomizers({
    availability,
    availabilityObj,
    calendarState: state,
    getRange,
    isBookingFlow,
    minStay,
    tooltipRef,
  })

  const {
    handleApply,
    handleCalendarChange,
    handleCalendarClickDay,
    handleCancel,
    handleClear,
    handleDatePickerClick,
  } = useCalendarEvents({
    appDispatch,
    availability,
    closeDatePicker,
    directionRef,
    dispatch,
    endDate,
    fullScreenRef,
    getBehavior,
    getRangeAvailability,
    getSingleAvailability,
    isBookingFlow,
    isDesktop,
    listingId,
    minStay,
    onApply,
    onChange,
    onClearDates,
    openDatePicker,
    setDatesErrorMsg,
    startDate,
  })

  const getDatesCalendarProps = () => {
    const nextCalendarClassName = isBookingFlow ? 'react-calendar--booking' : ''
    const nextTileDisabled = !isBookingFlow ? getTileDisabled : undefined
    return {
      activeStartDate,
      calendarClassName: nextCalendarClassName,
      calendarContainerRef,
      calendarIsBlocked,
      endDate,
      formatShortWeekday: getFormatShortWeekday,
      handleApply,
      handleCalendarChange,
      handleCalendarClickDay,
      handleCancel,
      handleClear,
      setActiveStartDate,
      startDate,
      tileClassName: getTileClassName,
      tileContent: getTileContent,
      tileDisabled: nextTileDisabled,
      calendarKey,
      stayOnlyInfo,
    }
  }

  useCalendarEffects({
    appDispatch,
    availability,
    calendarContainerRef,
    closeDatePicker,
    datePickerRef,
    disableFocusChange,
    dispatch,
    endDate,
    fullScreenRef,
    getRange,
    getRangeAvailability,
    handleApply,
    handleClear,
    isBookingFlow,
    isMobile,
    isValidCheckIn,
    onApply,
    startDate,
    setDatesErrorMsg,
    tooltip,
    tooltipRef,
  })

  return {
    appDispatch,
    calendarDialogRef,
    calendarOpen,
    combinedDate,
    datePickerRef,
    formattedEndDate,
    formattedStartDate,
    fullScreenRef,
    getDatesCalendarProps,
    handleDatePickerClick,
    isSearchPage,
    isBookingFlow,
    setStartDate,
    small,
    width,
  }
}

export { useCalendar }
