import { useCallback } from 'react'
import dayjs from 'dayjs'
import keyBy from 'lodash/keyBy'
import type { Dayjs } from 'dayjs'
import type { RefObject } from 'react'

import type { CalendarState } from './useCalendarState'

import DatesConstants from 'constants/dates'
import AvailabilityCodes from 'constants/availableCodes'
import availabilityCodes from 'constants/availableCodes'

import type {
  Availability,
  AvailabilityUnit,
  MinStay,
} from 'types/externalData'

export type AvailabilityResult = {
  isValid: boolean
  reason: {
    label: string
    type: string
  }
  stayOnlyInfo?: {
    show: boolean
    label: string
  }
  reachableDates?: Array<string>
}

export type UseCalendarTooling = (props: {
  availability?: Availability | null
  availabilityObj: Record<string, AvailabilityUnit>
  calendarOpen: boolean
  directionRef: RefObject<'ltr' | 'rtl'>
  isValidCheckIn: boolean
  minStay?: MinStay | null
  calendarState: CalendarState
}) => CalendarTooling

export type CalendarTooling = {
  getRange: (rangeStart: Dayjs, rangeEnd: Dayjs) => Array<Dayjs>
  getRangeAvailability: (
    selectionStartDate: Dayjs,
    selectionEndDate: Dayjs,
  ) => AvailabilityResult
  getSingleAvailability: (selectionStartDate: Dayjs) => AvailabilityResult
  getBehavior: (
    selectionStartDate: Dayjs,
    selectionEndDate: Dayjs | null,
  ) => {
    isRangeSelection: boolean
    isSingleSelection: boolean
  }
  getNumberOfNights: (
    selectionStartDate: Dayjs,
    selectionEndDate: Dayjs,
  ) => number
}

export type AvailabilityStatus = {
  reachableDaysList: Array<string>
  hasFoundANotAvailableDate: boolean
}

const POSSIBLE_REASONS = {
  CHECK_IN_NOT_AVAILABLE: {
    type: 'CHECK_IN_NOT_AVAILABLE',
    label: 'This date is not available for check-in.',
  },
  CHECK_OUT_NOT_AVAILABLE: {
    type: 'CHECK_OUT_NOT_AVAILABLE',
    label: 'This date is not available for check-out.',
  },
  RANGE_INVALID: {
    type: 'RANGE_INVALID',
    label: 'This date is not available for check-out.',
  },
  RANGE_INSUFFICIENT: {
    type: 'RANGE_INSUFFICIENT',
    label: 'This property requires a minimum ${minStay} night stay.',
  },
  CHECK_IN_ONLY: {
    type: 'CHECK_IN_ONLY',
    label: 'This date is only available for check-in.',
  },
  CHECK_OUT_ONLY: {
    type: 'CHECK_OUT_ONLY',
    label: 'This date is only available for check-out.',
  },
  CHECK_IN_AT_PAST: {
    type: 'CHECK_IN_AT_PAST',
    label: 'Check-in cannot be in the past.',
  },
  CHECK_OUT_AT_PAST: {
    type: 'CHECK_OUT_AT_PAST',
    label: 'Check-out must be after check-in date.',
  },
  CHECK_OUT_SAME_AS_CHECK_IN: {
    type: 'CHECK_OUT_SAME_AS_CHECK_IN',
    label: 'Check-out must be after check-in date.',
  },
} as const

const useCalendarTooling: UseCalendarTooling = (props) => {
  const {
    availability,
    availabilityObj,
    directionRef,
    minStay,
    calendarState,
  } = props

  const getRange: CalendarTooling['getRange'] = (
    rangeStart = dayjs(),
    rangeEnd = dayjs(),
  ) => {
    let currentDate = rangeStart
    const selectionRange: Array<Dayjs> = []
    while (currentDate.isBefore(rangeEnd) || currentDate.isSame(rangeEnd)) {
      selectionRange.push(currentDate)
      currentDate = currentDate.add(1, 'day')
    }
    return selectionRange
  }

  const getAvailabilityStatusForADate = useCallback(
    (desiredDate, selectionRange) => {
      const lookupDates = keyBy(selectionRange, (selectionRangeDay) =>
        selectionRangeDay.format(),
      )

      const filteredAvailability = availability?.filter(
        (availabilityDay) => !!lookupDates[availabilityDay.date],
      )

      const availabilitySpecificDay = filteredAvailability?.find(
        (filteredAvailabilityDate) =>
          dayjs(filteredAvailabilityDate?.date).isSame(desiredDate, 'day'),
      )
      const availabilityStatus =
        availabilitySpecificDay?.code || AvailabilityCodes['NOT_AVAILABLE']

      return availabilityStatus
    },
    [availability],
  )

  const getNumberOfNights: CalendarTooling['getNumberOfNights'] = (
    selectionStartDate,
    selectionEndDate,
  ) => selectionEndDate.diff(selectionStartDate, 'days') || 0

  const getBehavior: CalendarTooling['getBehavior'] = (
    selectionStartDate,
    selectionEndDate,
  ) => {
    const isRangeSelection = !!selectionEndDate?.isValid()

    const isSingleSelection = !isRangeSelection
    return {
      isSingleSelection,
      isRangeSelection,
    }
  }

  const getNextAvailabilityByCode = (
    selectionDate: Dayjs,
    codes: Array<String>,
  ) => {
    const trimmedAvailability = availability?.filter((availabilityUnit) =>
      dayjs(availabilityUnit.date).isAfter(selectionDate),
    )
    return trimmedAvailability?.find((availabilityUnit) =>
      codes.includes(availabilityUnit.code),
    )
  }

  const filterMinStay = useCallback(
    (range) => {
      const lookupDates = keyBy(range, (rangeDate) => rangeDate.format())
      const filteredMinStay =
        minStay
          ?.filter((minStayUnit) => !!lookupDates[minStayUnit.date])
          .slice(0, -1) || []

      return filteredMinStay
    },
    [minStay],
  )

  const getReachableDates = useCallback(
    (selectionStartDate) => {
      const reachableDates = availability
        ?.reduce(
          (status, availabilityUnit) => {
            const availabilityDate = dayjs(availabilityUnit.date)
            const availabilityCode = availabilityUnit.code
            const isSameOrAfterStartDate =
              availabilityDate.isSame(selectionStartDate) ||
              availabilityDate.isAfter(selectionStartDate)
            const isAvailable =
              isSameOrAfterStartDate &&
              availabilityCode !== AvailabilityCodes.NOT_AVAILABLE
            const isReachable = isAvailable && !status.hasFoundANotAvailableDate

            if (isSameOrAfterStartDate && !isAvailable) {
              return {
                ...status,
                hasFoundANotAvailableDate: true,
              }
            }

            if (isReachable) {
              return {
                ...status,
                reachableDaysList: [
                  ...status.reachableDaysList,
                  availabilityDate.format(DatesConstants.DEFAULT),
                ],
              }
            }

            return status
          },
          {
            reachableDaysList: [],
            hasFoundANotAvailableDate: false,
          } as AvailabilityStatus,
        )
        .reachableDaysList.filter((currentReachableDate, index) => {
          const mappedReachableDaysList = getRange(
            selectionStartDate,
            dayjs(currentReachableDate),
          )
          const filteredMinStay = filterMinStay(mappedReachableDaysList)

          const largerMinStay = Math.max(
            ...filteredMinStay.map((minStayUnit) => minStayUnit.minStay),
          )

          return ++index > largerMinStay
        })

      return reachableDates
    },
    [availability, filterMinStay],
  )

  const getSingleAvailability: CalendarTooling['getSingleAvailability'] = (
    selectionStartDate,
  ) => {
    let reason = {
      type: '',
      label: '',
    }
    const availabilityStatus = getAvailabilityStatusForADate(
      selectionStartDate,
      [selectionStartDate],
    )

    const nextAvailabilityCodes = [
      availabilityCodes['AVAILABLE'],
      availabilityCodes['STAY_OR_CHECK_IN'],
    ]
    const nextAvailabilityUnit = getNextAvailabilityByCode(
      selectionStartDate,
      nextAvailabilityCodes,
    )

    const nextAvailableDate = dayjs(nextAvailabilityUnit?.date).format(
      DatesConstants['MONTH_NAME_LARGE_FORMAT'],
    )

    const selectionIsNotAvailable = [AvailabilityCodes['NOT_AVAILABLE']].some(
      (currentAvailabilityStatus) =>
        currentAvailabilityStatus === availabilityStatus,
    )

    const selectionIsAtPast = selectionStartDate.isBefore(
      dayjs().add(-1, 'day'),
    )

    const selectionIsToday = selectionStartDate.isSame(dayjs(), 'd')

    const selectionIsStayOnly =
      availabilityStatus === AvailabilityCodes['STAY_ONLY']

    const selectionIsCheckOutOnly =
      availabilityStatus === AvailabilityCodes['ONLY_CHECK_OUT']

    const minStaySpecificDay: number =
      minStay?.find((minStayUnit) =>
        dayjs(minStayUnit.date).isSame(selectionStartDate),
      )?.minStay || 0
    const requiredMinStayDays = getRange(
      selectionStartDate,
      selectionStartDate.add(minStaySpecificDay, 'days'),
    ).slice(1)

    const selectionMinStayIsInsufficient = requiredMinStayDays.some(
      (requiredMinStayDay) => {
        const availabilityCode =
          availabilityObj[
            requiredMinStayDay.format(DatesConstants.SPLIT_BY_DASH)
          ]?.code
        return availabilityCode === AvailabilityCodes['NOT_AVAILABLE']
      },
    )

    const isValid =
      !selectionIsToday &&
      !selectionIsAtPast &&
      !selectionIsCheckOutOnly &&
      !selectionIsNotAvailable &&
      !selectionIsStayOnly &&
      !selectionMinStayIsInsufficient

    const reachableDates = getReachableDates(selectionStartDate)

    if (selectionMinStayIsInsufficient) {
      reason = {
        ...POSSIBLE_REASONS['RANGE_INSUFFICIENT'],
        label: POSSIBLE_REASONS['RANGE_INSUFFICIENT'].label.replace(
          '${minStay}',
          minStaySpecificDay.toString(),
        ),
      }
    }

    if (selectionIsNotAvailable || selectionIsStayOnly || selectionIsToday) {
      reason = POSSIBLE_REASONS['CHECK_IN_NOT_AVAILABLE']
    }

    if (selectionIsAtPast) {
      reason = POSSIBLE_REASONS['CHECK_IN_AT_PAST']
    }

    if (selectionIsCheckOutOnly) {
      reason = POSSIBLE_REASONS['CHECK_OUT_ONLY']
    }

    return {
      isValid,
      stayOnlyInfo: {
        show: selectionIsStayOnly,
        label: `The next available date for check in is ${nextAvailableDate}`,
      },
      reason,
      reachableDates,
    }
  }

  const getRangeAvailability: CalendarTooling['getRangeAvailability'] =
    useCallback(
      (selectionStartDate, selectionEndDate) => {
        const selectionRange = getRange(selectionStartDate, selectionEndDate)
        const selectionNumberOfNights = getNumberOfNights(
          selectionStartDate,
          selectionEndDate,
        )

        const nextAvailabilityCodes = [
          availabilityCodes['AVAILABLE'],
          availabilityCodes['STAY_OR_CHECK_OUT'],
          availabilityCodes['ONLY_CHECK_IN_OR_OUT'],
          availabilityCodes['ONLY_CHECK_OUT'],
        ]
        const nextAvailabilityUnit = getNextAvailabilityByCode(
          selectionEndDate,
          nextAvailabilityCodes,
        )

        const nextAvailableDate = dayjs(nextAvailabilityUnit?.date).format(
          DatesConstants['MONTH_NAME_LARGE_FORMAT'],
        )

        const reachableDates = calendarState.reachableDates?.slice(1, -1)

        const lastUnreachableDate = dayjs(reachableDates?.shift()).add(
          -1,
          'day',
        )

        const unreachableRange = getRange(
          selectionStartDate,
          lastUnreachableDate,
        )
        const filteredMinStayForComparing = filterMinStay(selectionRange)
        const filteredMinStayForLabeling = unreachableRange.length
          ? filterMinStay(unreachableRange)
          : filteredMinStayForComparing

        const largerMinStayForComparing = Math.max(
          ...filteredMinStayForComparing.map(
            (minStayUnit) => minStayUnit.minStay,
          ),
        )
        const largerMinStayForLabeling = Math.max(
          ...filteredMinStayForLabeling.map(
            (minStayUnit) => minStayUnit.minStay,
          ),
        )

        const finalMinStayForComparing =
          largerMinStayForComparing < 0 ? 0 : largerMinStayForComparing
        const finalMinStayForLabeling =
          largerMinStayForLabeling < 0 ? 0 : largerMinStayForLabeling

        const selectionIsInsufficient =
          selectionNumberOfNights < finalMinStayForComparing
        const startDayAvailabilityStatus = getAvailabilityStatusForADate(
          selectionStartDate,
          selectionRange,
        )
        const endDayAvailabilityStatus = getAvailabilityStatusForADate(
          selectionEndDate,
          selectionRange,
        )

        const selectionDatesAreEqual = selectionStartDate.isSame(
          selectionEndDate,
          'day',
        )
        const selectionEndDateIsAtPast = directionRef.current === 'rtl'

        const selectionStartDateIsNotAvailable =
          startDayAvailabilityStatus === AvailabilityCodes['NOT_AVAILABLE'] ||
          selectionStartDate.isBefore(dayjs())

        const selectionEndDateIsStayOnly =
          endDayAvailabilityStatus === AvailabilityCodes['STAY_ONLY']

        const selectionEndDateIsNotAvailable =
          endDayAvailabilityStatus === AvailabilityCodes['NOT_AVAILABLE']

        const selectionEndDateIsCheckInOnly =
          endDayAvailabilityStatus === AvailabilityCodes['ONLY_CHECK_IN']

        const rangeContainsNotAvailableDays =
          selectionRange.some((selectionCurrentDay) => {
            const availabilityForSelectionCurrentDay =
              getAvailabilityStatusForADate(selectionCurrentDay, selectionRange)
            return [AvailabilityCodes['NOT_AVAILABLE']].some(
              (availabilityCode) =>
                availabilityCode === availabilityForSelectionCurrentDay,
            )
          }) &&
          [
            selectionStartDateIsNotAvailable,
            selectionEndDateIsNotAvailable,
            selectionEndDateIsCheckInOnly,
            selectionEndDateIsStayOnly,
          ].some((condition) => Boolean(!condition))

        const isValid = [
          rangeContainsNotAvailableDays,
          selectionEndDateIsCheckInOnly,
          selectionEndDateIsNotAvailable,
          selectionEndDateIsStayOnly,
          selectionIsInsufficient,
          selectionStartDateIsNotAvailable,
          selectionEndDateIsAtPast,
          selectionDatesAreEqual,
        ].every((condition) => Boolean(!condition))

        if (selectionDatesAreEqual) {
          return {
            reason: POSSIBLE_REASONS['CHECK_OUT_SAME_AS_CHECK_IN'],
            isValid,
          }
        }

        if (selectionEndDateIsNotAvailable || selectionEndDateIsStayOnly) {
          return {
            reason: POSSIBLE_REASONS['CHECK_OUT_NOT_AVAILABLE'],
            isValid,
            stayOnlyInfo: {
              show: selectionEndDateIsStayOnly,
              label: `The next available date for check out is ${nextAvailableDate}`,
            },
          }
        }

        if (selectionEndDateIsAtPast) {
          return {
            isValid,
            reason: POSSIBLE_REASONS['CHECK_OUT_AT_PAST'],
          }
        }

        if (selectionEndDateIsCheckInOnly) {
          return {
            reason: POSSIBLE_REASONS['CHECK_IN_ONLY'],
            isValid,
          }
        }

        if (rangeContainsNotAvailableDays) {
          return {
            isValid,
            reason: POSSIBLE_REASONS['RANGE_INVALID'],
          }
        }

        if (selectionIsInsufficient) {
          const interpolattedReason = {
            ...POSSIBLE_REASONS['RANGE_INSUFFICIENT'],
            label: POSSIBLE_REASONS['RANGE_INSUFFICIENT'].label.replace(
              '${minStay}',
              finalMinStayForLabeling.toString(),
            ),
          }
          return {
            reason: interpolattedReason,
            isValid,
          }
        }

        return {
          isValid,
          reason: {
            label: '',
            type: '',
          },
        }
      },
      [
        calendarState.reachableDates,
        directionRef,
        filterMinStay,
        getAvailabilityStatusForADate,
        getNextAvailabilityByCode,
      ],
    )

  return {
    getRange,
    getRangeAvailability,
    getSingleAvailability,
    getNumberOfNights,
    getBehavior,
  }
}

export { useCalendarTooling }
