import React, { useState, useRef, useEffect, useCallback } from 'react'
import { enableBodyScroll, disableBodyScroll } from 'body-scroll-lock'
import classNames from 'classnames'

import { useDispatch, useSelect } from 'store/index'
import { setSelectedPets, setShouldApplyFilters } from 'store/search'
import { isPetAllowed as isPetAllowedSelector } from 'store/search/search.selectors'

import { setGuestPickerFocused } from 'reducers/uiState'

import { uiState } from 'selectors/index'

import useTimeout from 'hooks/useTimeout'
import { useCertainPage } from 'hooks/useCertainPage'

import style from './Guests.module.scss'

import Pets from '../Pets/Pets'

import { DesktopBreakpoint } from 'config/Breakpoints'

import CaretIcon from 'assets/icons/icon-caret.svg'
import GuestsIcon from 'assets/icons/icon-guests.svg'
import PlusIcon from 'assets/icons/icon-plus.svg'
import MinusIcon from 'assets/icons/icon-minus.svg'
import CloseIcon from 'assets/icons/icon-close.svg'

export type GuestsProps = {
  value: {
    adults: number
    children: number
    infants: number
    pets: number
    total: number
  }
  onApply: (
    adultGuests: number,
    childGuests: number,
    infantGuests: number,
    petGuests: number,
    totalGuests: number,
  ) => void
  min?: number
  max?: number
  refine?: (...args: any[]) => void
  currentRefinement?: { min: number; max: number }
  small?: boolean
  tiny?: boolean
  hasIcon?: boolean
  onClearGuests: () => void
  petsAllowed?: boolean
  disableFocusChange?: boolean
  hasError?: boolean
}

const Guests: React.FC<GuestsProps> = ({
  disableFocusChange,
  hasError,
  hasIcon,
  max,
  min,
  onApply,
  onClearGuests,
  petsAllowed,
  refine,
  small,
  tiny,
  value,
}) => {
  const isPetAllowed: boolean = useSelect(isPetAllowedSelector)
  const width = useSelect((state) => state.uiState.width)
  const isMobile = useSelect(uiState.selectIsMobile)
  const [guestValue, setGuestValue] = useState({
    adults: 0,
    children: 0,
    infants: 0,
    pets: 0,
    total: 0,
  })
  const guestTotal = guestValue?.adults + guestValue?.children
  const guestsPickerRef = useRef<HTMLDivElement>(null)

  const isBookingFlow = useCertainPage(
    /vacation-rentals.*\/[0-9]/,
    /booking.*\/summary/,
    /booking.*\/payment-details/,
  )

  const isSearchPage = useCertainPage(/vacation-rentals\/search/)

  const shouldLockDecreaseAdults = [
    guestValue.adults < 1,
    (guestValue.children > 0 || guestValue.infants > 0) &&
      guestValue.adults <= 1,
  ].some((condition) => Boolean(condition))
  const shouldLockIncreaseTotal = guestTotal === max
  const shouldRenderPetsCounter = !!petsAllowed || isSearchPage

  // Redux
  const appDispatch = useDispatch()
  const guestPickerFocused = useSelect(
    (state) => state.uiState.guestPickerFocused,
  )

  const adultsTimeout = useTimeout()
  const childrenTimeout = useTimeout()
  const infantsTimeout = useTimeout()

  const handleTimeouts = (guestType: string) => {
    switch (guestType) {
      case 'adults':
        adultsTimeout.setIsRunning(true)
        break
      case 'children':
        childrenTimeout.setIsRunning(true)
        break
      case 'infants':
        infantsTimeout.setIsRunning(true)
        break
      default:
        break
    }
  }

  const handleAdultsButtonClick = (evt) => {
    evt.preventDefault()
    const { action } = evt.currentTarget.dataset
    const nextAdults =
      action === 'decrease' ? guestValue.adults - 1 : guestValue.adults + 1
    const nextTotals =
      action === 'decrease' ? guestValue.adults - 1 : guestValue.adults + 1
    const shouldHandleTimeOuts =
      document.body.classList.value.includes('Search')

    setGuestValue((prevGuestValue) => ({
      ...prevGuestValue,
      adults: nextAdults,
      total: nextTotals,
    }))

    if (!shouldHandleTimeOuts) return

    handleTimeouts('adults')
  }

  const handleChildrenButtonClick = (evt) => {
    evt.preventDefault()
    const { action } = evt.currentTarget.dataset
    const nextAdults =
      action === 'decrease' ? guestValue.adults : guestValue.adults || 1
    const nextChildren =
      action === 'decrease' ? guestValue.children - 1 : guestValue.children + 1
    const nextTotal =
      action === 'decrease' ? guestValue.total - 1 : guestValue.total + 1
    const shouldHandleTimeOuts =
      document.body.classList.value.includes('Search')

    setGuestValue((prevGuestValue) => ({
      ...prevGuestValue,
      adults: nextAdults,
      children: nextChildren,
      total: nextTotal,
    }))

    if (!shouldHandleTimeOuts) return

    handleTimeouts('children')
  }

  const handleClear = () => {
    onClearGuests()

    if (refine && isSearchPage) {
      if (min && max) {
        setTimeout(() => {
          refine('')
        })
      }
    }
  }

  const handleCancel = () => {
    appDispatch(setGuestPickerFocused(false))
    setGuestValue(value)

    if (width <= DesktopBreakpoint && guestsPickerRef.current) {
      enableBodyScroll(guestsPickerRef.current)
    }
  }

  const handleApply = useCallback(() => {
    appDispatch(setGuestPickerFocused(false))
    onApply(
      guestValue.adults,
      guestValue.children,
      guestValue.infants,
      Number(isPetAllowed),
      guestValue.total,
    )

    if (refine && isSearchPage) {
      if (min && max) {
        refine({
          min: Math.max(guestValue.total, min),
          max: max,
        })
      }
      appDispatch(setShouldApplyFilters(true))
    }
  }, [
    appDispatch,
    guestValue.adults,
    guestValue.children,
    guestValue.infants,
    isPetAllowed,
    guestValue.total,
    max,
    min,
    onApply,
    refine,
    isSearchPage,
  ])

  useEffect(() => {
    if (guestPickerFocused && width <= DesktopBreakpoint) {
      guestsPickerRef.current && disableBodyScroll(guestsPickerRef.current)
    }
  }, [guestPickerFocused, width])

  useEffect(() => {
    setGuestValue(value)
  }, [value])

  useEffect(() => {
    if (isMobile) return
    const handleClickOutside = (e: MouseEvent) => {
      if (
        !disableFocusChange &&
        guestPickerFocused &&
        guestsPickerRef.current &&
        !guestsPickerRef.current.contains(e.target as Node)
      ) {
        handleApply()
      }
    }

    const handleSearchButtonHover = (e: MouseEvent) => {
      if (guestPickerFocused && value !== guestValue) {
        onApply(
          guestValue.adults,
          guestValue.children,
          guestValue.infants,
          Number(isPetAllowed),
          guestValue.total,
        )
      }
    }

    document.addEventListener('mousedown', handleClickOutside, false)
    document
      .getElementById('search_homes_btn')
      ?.addEventListener('mouseover', handleSearchButtonHover, false)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside, false)
      document
        .getElementById('search_homes_btn')
        ?.removeEventListener('mouseover', handleSearchButtonHover, false)
    }
  }, [
    appDispatch,
    disableFocusChange,
    guestPickerFocused,
    handleApply,
    max,
    min,
    refine,
    isSearchPage,
    value.total,
    width,
    onApply,
    guestValue,
    guestValue.total,
    value,
    isPetAllowed,
    isMobile,
  ])

  return (
    <div
      className={`${style.select} ${value.total ? style.hasValue : ''} ${
        guestValue.total > 0 ? style.hasTotal : ''
      } ${small ? style.small : ''} ${tiny ? style.tiny : ''} ${
        hasIcon ? style.withIcon : ''
      }`}
      ref={guestsPickerRef}
    >
      <div
        className={classNames(style.value, {
          [style.focused]: guestPickerFocused,
          [style.guestPickerError]: hasError,
        })}
        onClick={() => {
          appDispatch(setGuestPickerFocused(!guestPickerFocused))

          if (width <= DesktopBreakpoint && guestsPickerRef.current) {
            if (guestPickerFocused) {
              enableBodyScroll(guestsPickerRef.current)
            } else {
              disableBodyScroll(guestsPickerRef.current)
            }
          }
        }}
        role="button"
      >
        {hasIcon ? (
          small ? (
            <GuestsIcon className={style.icon} width={18} />
          ) : (
            <GuestsIcon className={style.icon} width={23} />
          )
        ) : null}

        <span className={style.guestsCopy} data-testid="guestsDropDown">
          {'Guests'}
          {guestTotal > 0 ? ` (${guestTotal})` : ''}
        </span>
        {!tiny && (
          <CaretIcon
            className={`${style.caret} ${
              guestPickerFocused ? style.focused : ''
            }`}
            height="7px"
            width="12px"
          />
        )}
      </div>
      <div
        className={`${style.options} ${
          guestPickerFocused ? style.focused : ''
        }`}
      >
        <header className={style.guestHeader}>
          <div>
            <button
              className={style.btn}
              data-testid="clearAll"
              onClick={() => handleClear()}
            >
              Clear All
            </button>
          </div>
          <div>
            <span className={style.headerLabel}>Select Guests</span>
          </div>
          <div>
            <button className={style.btn} onClick={() => handleCancel()}>
              <CloseIcon width={11} />
            </button>
          </div>
        </header>
        {isBookingFlow && (
          <ul className={style.guestsDetails}>
            <li>
              This property has a maximum occupancy of {max} guests, not
              including infants.
            </li>
            <li>
              Pets are{petsAllowed ? ' ' : ' not '}allowed at this property.
            </li>
          </ul>
        )}
        <div
          className={`${style.guestSection} ${
            adultsTimeout.isRunning ? style.touchHilight : ''
          }`}
          data-testid="adultsControl"
        >
          <div className={style.guestLabel}>Adults</div>
          <div className={style.control}>
            <button
              data-action="decrease"
              data-testid="decreaseAdultsBtn"
              disabled={shouldLockDecreaseAdults}
              onClick={handleAdultsButtonClick}
            >
              <MinusIcon width="100%" />
            </button>
            <div>{guestValue.adults}</div>
            <button
              data-action="increase"
              data-testid="increaseAdultsBtn"
              disabled={shouldLockIncreaseTotal}
              onClick={handleAdultsButtonClick}
            >
              <PlusIcon width="100%" />
            </button>
          </div>
        </div>
        <div
          className={`${style.guestSection} ${
            childrenTimeout.isRunning ? style.touchHilight : ''
          }`}
        >
          <div className={style.guestLabel}>
            Children
            <div className={style.subLabel}>Ages 2-12</div>
          </div>
          <div className={style.control}>
            <button
              data-action="decrease"
              data-testid="decreaseChildrenBtn"
              disabled={guestValue.children <= 0}
              onClick={handleChildrenButtonClick}
            >
              <MinusIcon width="100%" />
            </button>
            <div>{guestValue.children}</div>
            <button
              data-action="increase"
              data-testid="increaseChildrenBtn"
              disabled={shouldLockIncreaseTotal}
              onClick={handleChildrenButtonClick}
            >
              <PlusIcon width="100%" />
            </button>
          </div>
        </div>
        <div
          className={`${style.guestSection} ${
            infantsTimeout.isRunning ? style.touchHilight : ''
          }`}
        >
          <div className={style.guestLabel}>Infants</div>
          <div className={style.control}>
            <button
              data-testid="decreaseInfantsBtn"
              disabled={guestValue.infants <= 0}
              onClick={(e) => {
                e.preventDefault()
                setGuestValue({
                  adults: guestValue.adults,
                  children: guestValue.children,
                  infants: guestValue.infants - 1,
                  pets: Number(isPetAllowed),
                  total: guestValue.total,
                })
                if (document.body.classList.value.includes('Search')) {
                  handleTimeouts('infants')
                }
              }}
            >
              <MinusIcon width="100%" />
            </button>
            <div>{guestValue.infants}</div>
            <button
              data-testid="increaseInfantsBtn"
              onClick={(e) => {
                e.preventDefault()
                setGuestValue({
                  adults: guestValue.adults || 1,
                  children: guestValue.children,
                  infants: guestValue.infants + 1,
                  pets: Number(isPetAllowed),
                  total: guestValue.total,
                })
                if (document.body.classList.value.includes('Search')) {
                  handleTimeouts('infants')
                }
              }}
            >
              <PlusIcon width="100%" />
            </button>
          </div>
        </div>
        {shouldRenderPetsCounter && (
          <Pets
            className={style.petsContainer}
            pets={Number(isPetAllowed)}
            setPets={(pets: number) => {
              setGuestValue({
                adults: guestValue.adults,
                children: guestValue.children,
                infants: guestValue.infants,
                pets: Number(isPetAllowed),
                total: guestValue.total,
              })
              appDispatch(
                setSelectedPets({ isPetAllowed: pets, ignoreFiltering: true }),
              )
            }}
          />
        )}
        <footer className={style.footer}>
          {width <= DesktopBreakpoint ? (
            <button
              className={`${'btn-primary'} ${style.applyBtn}`}
              data-testid="guestsApplyBtnMbl"
              onClick={() => {
                guestsPickerRef.current &&
                  enableBodyScroll(guestsPickerRef.current)
                handleApply()
              }}
            >
              Apply
            </button>
          ) : (
            <>
              <button
                className={`${style.btn} ${style.clearBtn}`}
                data-testid="guestsClearBtn"
                onClick={() => {
                  handleClear()
                }}
              >
                Clear
              </button>
              <button
                className={style.btn}
                data-testid="guestsApplyBtn"
                onClick={() => {
                  handleApply()
                }}
              >
                Apply
              </button>
            </>
          )}
        </footer>
      </div>
    </div>
  )
}

export default Guests
