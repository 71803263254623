import dayjs from 'dayjs'
import { useMemo } from 'react'

import DatesConstants from 'constants/dates'

import type { Availability } from 'types/externalData'

const parseAvailability = (availability: Availability) =>
  availability.reduce((obj: Record<string, Availability[0]>, item) => {
    obj[item.date.split('T')[0]] = item
    return obj
  }, {})

const useCalendarMemos = (props) => {
  const { availability, visibleStartDate, visibleEndDate } = props
  const availabilityObj = useMemo(
    () => availability && parseAvailability(availability),
    [availability],
  )
  const formattedStartDate = useMemo(
    () =>
      visibleStartDate ? dayjs(visibleStartDate).format('MM/DD/YYYY') : '',
    [visibleStartDate],
  )
  const formattedEndDate = useMemo(
    () => (visibleEndDate ? dayjs(visibleEndDate).format('MM/DD/YYYY') : ''),
    [visibleEndDate],
  )
  const combinedDate = useMemo(() => {
    if (!visibleStartDate || !visibleEndDate) return 'Dates'
    const dayjsStart = dayjs(visibleStartDate, DatesConstants.DEFAULT)
    const dayjsEnd = dayjs(visibleEndDate, DatesConstants.DEFAULT)
    if (
      dayjsStart.month() === dayjsEnd.month() &&
      dayjsStart.year() === dayjsEnd.year()
    ) {
      return `${dayjsStart.format('MMM D')} - ${dayjsEnd.format('D')}`
    }
    return `${dayjsStart.format('MMM D')} - ${dayjsEnd.format('MMM D')}`
  }, [visibleStartDate, visibleEndDate])

  return {
    availabilityObj,
    formattedStartDate,
    formattedEndDate,
    combinedDate,
  }
}

export { useCalendarMemos }
