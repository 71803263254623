import React from 'react'

import useTimeout from 'hooks/useTimeout'

import Radio from 'components/Forms/Radio'

import style from './Pets.module.scss'

export type PetsProps = {
  pets: number
  setPets: (pets: number) => void
  className?: string
}

const Pets: React.FC<PetsProps> = ({ pets, setPets, className = '' }) => {
  const timeout = useTimeout(450)

  const handleChange = (value: string) => {
    setPets(parseInt(value))
    if (document.body.classList.value.includes('Search')) {
      timeout.setIsRunning(true)
    }
  }

  return (
    <div
      className={`${style.petsContainer} ${
        timeout.isRunning ? style.touchHilight : ''
      } ${className}`}
    >
      <div className={style.guestLabel}>Pets</div>
      <div className={style.radioControls}>
        <Radio
          checked={+pets === 0}
          dataTestId="petsNoRadioBtn"
          id="pets-no"
          label={'No'}
          name="pets"
          onValueChange={handleChange}
          value={0}
        />
        <Radio
          checked={+pets === 1}
          dataTestId="petsYesRadioBtn"
          id="pets-yes"
          label={'Yes'}
          name="pets"
          onValueChange={handleChange}
          value={1}
        />
      </div>
    </div>
  )
}

export default Pets
