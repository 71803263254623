// TODO: Calendar available dates are wrong if date picker is opened before availability api responds
// TODO: Using redux for date picker focus state breaks ask a question modal date picker
import React, { useCallback } from 'react'
import { createPortal } from 'react-dom'
import dayjs from 'dayjs'
import isBetween from 'dayjs/plugin/isBetween'
import dynamic from 'next/dynamic'
import classNames from 'classnames'

import { openDatePicker } from 'reducers/uiState'

import { useCalendar } from 'hooks/useCalendar/useCalendar'

import { DesktopBreakpoint } from 'config/Breakpoints'

import CalendarIcon from 'assets/icons/icon-calendar.svg'
import ArrowDivider from 'assets/icons/icon-arrowDivider.svg'

import type { Availability, MinStay } from 'types/externalData'

const DatesCalendar = dynamic(() => import('./DatesCalendar'))

dayjs.extend(isBetween)

export type DatesProps = {
  availability?: Availability | null
  combinedInputs?: boolean
  direction?: 'left' | 'right' | 'center' | 'none'
  disabled?: boolean
  disableFocusChange?: boolean
  hasError?: boolean
  initialEndDate?: string
  initialStartDate?: string
  listingId?: string
  minStay?: MinStay | null
  onApply?: (startDate: string, endDate: string) => void
  onChange?: (startDate: string, endDate: string) => void
  onClearDates?: () => void
  setDatesErrorMsg?: (datesAreInvalid: string) => void
  small?: boolean
}

const Dates: React.FC<DatesProps> = (props) => {
  const {
    appDispatch,
    calendarDialogRef,
    calendarOpen,
    combinedDate,
    datePickerRef,
    formattedEndDate,
    formattedStartDate,
    fullScreenRef,
    getDatesCalendarProps,
    handleDatePickerClick,
    isSearchPage,
    isBookingFlow,
    setStartDate,
    small,
    width,
  } = useCalendar(props)
  const { direction, hasError } = props
  const showCombinedFields =
    (!!formattedEndDate && isBookingFlow) || isSearchPage
  const renderCalendarByDevice = useCallback<
    (...args: string[]) => React.ReactNode
  >(() => {
    const Calendar = <DatesCalendar {...getDatesCalendarProps()} />
    return width > DesktopBreakpoint
      ? Calendar
      : createPortal(Calendar, document.body)
  }, [getDatesCalendarProps, width])

  return (
    <div
      className={classNames('DateRangePickerContainer', {
        small,
        DateRangePickerContainerError: hasError,
        focused: isSearchPage && calendarOpen,
      })}
      id="DateRangePickerContainer_wrapper_div"
      ref={fullScreenRef}
    >
      <div className="DateRangePicker" ref={datePickerRef}>
        {showCombinedFields ? (
          <div
            className={`combinedDatePickerTrigger ${
              small && isSearchPage ? 'small' : ''
            }`}
            onClick={() => appDispatch(openDatePicker())}
          >
            <CalendarIcon onClick={handleDatePickerClick} width={18} />

            <span className="combinedDate" data-testid="combinedDateBtn">
              {combinedDate}
            </span>
          </div>
        ) : (
          <div
            className="DateRangePickerInput"
            id="DateRangePickerInput_wrapper_div"
          >
            <button
              aria-label="Check-in date calendar"
              className="DateRangePickerInput_calendarIcon"
              onClick={handleDatePickerClick}
            >
              <CalendarIcon width={small ? 18 : 22} />
            </button>
            <div className="DateInput">
              <input
                aria-describedby="DateInput__screen-reader-message-START_DATE"
                aria-label="Check-in"
                autoComplete="off"
                className="DateInput_input"
                id="START_DATE"
                name="START_DATE"
                onChange={(e) => setStartDate(e.target.value)}
                onClick={handleDatePickerClick}
                placeholder="Check-in"
                readOnly
                type="text"
                value={formattedStartDate}
              />
              <p
                className="DateInput_screenReaderMessage"
                id="DateInput__screen-reader-message-START_DATE"
              >
                Navigate forward to interact with the calendar and select a
                date. Press the question mark key to get the keyboard shortcuts
                for changing dates.
              </p>
            </div>
            <div
              aria-hidden={true}
              className="DateRangePickerInput_arrow"
              role="presentation"
            >
              <ArrowDivider height={50} width={22} />
            </div>
            <div className="DateInput">
              <input
                aria-describedby="DateInput__screen-reader-message-END_DATE"
                aria-label="Check-out"
                autoComplete="off"
                className="DateInput_input"
                id="END_DATE"
                name="END_DATE"
                onChange={(e) => setStartDate(e.target.value)}
                onClick={handleDatePickerClick}
                placeholder="Check-out"
                readOnly
                type="text"
                value={formattedEndDate}
              />
              <p
                className="DateInput_screenReaderMessage"
                id="DateInput__screen-reader-message-END_DATE"
              >
                Navigate backward to interact with the calendar and select a
                date. Press the question mark key to get the keyboard shortcuts
                for changing dates.
              </p>
            </div>
          </div>
        )}
        <div
          className={`calendarDialog ${
            direction === 'left'
              ? 'directionLeft'
              : direction === 'right'
              ? 'directionRight'
              : direction === 'center'
              ? 'directionCenter'
              : direction === 'none'
              ? ''
              : ''
          }`}
          ref={calendarDialogRef}
        >
          {calendarDialogRef.current &&
            calendarOpen &&
            renderCalendarByDevice()}
        </div>
      </div>
    </div>
  )
}

export default Dates
