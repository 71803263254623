import dayjs from 'dayjs'
import ReactTooltip from 'react-tooltip'
import type { RefObject } from 'react'
import type { Dayjs } from 'dayjs'

import { useCalendarSentinels } from './useCalendarSentinels'
import type { CalendarState } from './useCalendarState'

import type { AvailabilityUnit, MinStay } from 'types/externalData'

export type DatesCustomizers = {
  getTileClassName: (props: { date: Date }) => string | null
  getTileContent: (props: { date: Date }) => JSX.Element | null
  getTileDisabled: (props: { date: Date }) => boolean
  getFormatShortWeekday: (locale: string, date: Date) => string
}

export type UseCalendarCustomizers = (props: {
  availability: Array<AvailabilityUnit> | null | undefined
  availabilityObj: Record<string, AvailabilityUnit>
  calendarState: CalendarState
  getRange: (rangeStart: Dayjs, rangeEnd: Dayjs) => Array<Dayjs>
  isBookingFlow: boolean
  minStay?: MinStay | null
  tooltipRef: RefObject<HTMLDivElement>
}) => DatesCustomizers

const useCalendarCustomizers: UseCalendarCustomizers = (props) => {
  const {
    availability,
    availabilityObj,
    calendarState,
    getRange,
    isBookingFlow,
    minStay,
    tooltipRef,
  } = props

  const { startDate, endDate, tooltip } = calendarState

  const {
    tileIsAtInsuficentRange,
    tileIsAtPast,
    tileIsAtUnavailableDate,
    tileIsAtUnavailableRange,
    tileIsCheckInOnly,
    tileIsCheckOutOnly,
    tileIsDisabled,
    tileIsHoverable,
    tileIsLastSelected,
    tileIsSelected,
    tileIsUnreachable,
    tileIsUnreachableInitially,
    tileIsStayOnly,
  } = useCalendarSentinels({
    availability,
    availabilityObj,
    calendarState,
    endDate,
    getRange,
    isBookingFlow,
    minStay,
    startDate,
    tooltip,
  })

  const getTileClassName: DatesCustomizers['getTileClassName'] = (props) => {
    if (!isBookingFlow || !minStay || !availability) return null

    const isUnavailable = tileIsDisabled(props)
    const isStayOnly = tileIsStayOnly(props)
    const isAvailable = !isUnavailable
    const userWillInteract =
      (!startDate && !endDate) || (!!startDate && !!endDate)

    /* These function calls return booleans based on the
    date and current state of the calendar state */
    const isUnreachable = tileIsUnreachable(props)
    const isHoverable = userWillInteract || tileIsHoverable(props)

    const isUnreachableInitially =
      isAvailable && userWillInteract && tileIsUnreachableInitially(props)
    const isSelected = tileIsSelected(props)
    const isCheckInOnly = tileIsCheckInOnly(props)
    const isCheckOutOnly = tileIsCheckOutOnly(props)
    const isAtUnavailableRange = tileIsAtUnavailableRange(props)
    const hoverableClass = 'react-calendar__tile--hoverable'
    const unavailableClass = `react-calendar__tile--disabled ${
      isSelected ? 'react-calendar__tile--range-disabled' : ''
    }`
    const checkInOnlyClass = `react-calendar__tile--checkin-only ${
      isHoverable ? hoverableClass : ''
    }`
    const checkOutOnlyClass = `react-calendar__tile--checkout-only ${
      isHoverable ? hoverableClass : ''
    }`
    const unreachableClass = `react-calendar__tile--unreachable
    ${isCheckInOnly ? checkInOnlyClass : ''} ${
      isCheckOutOnly ? checkOutOnlyClass : ''
    } ${isHoverable ? hoverableClass : ''}`

    const invalidRangeClass = `react-calendar__tile--invalid-range ${
      isCheckInOnly ? checkInOnlyClass : ''
    } ${isCheckOutOnly ? checkOutOnlyClass : ''} 
    ${isHoverable ? hoverableClass : ''}
      `

    if (isStayOnly) return unreachableClass
    if (isUnreachableInitially) return unreachableClass
    if (isUnreachable) return unreachableClass
    if (isUnavailable) return unavailableClass
    if (isAtUnavailableRange) return invalidRangeClass
    if (isCheckInOnly) return checkInOnlyClass
    if (isCheckOutOnly) return checkOutOnlyClass
    if (isHoverable) return hoverableClass

    return null
  }

  const getOverridePosition: ReactTooltip['props']['overridePosition'] = (
    { left, top },
    _e,
    _t,
    node,
  ) => {
    return {
      top,
      left: typeof node === 'string' ? left : Math.max(left, 0),
    }
  }

  const getTileContent: DatesCustomizers['getTileContent'] = (props) => {
    if (!isBookingFlow || !minStay || !availability) return null
    const nextEndDate = endDate || tooltip.date

    /* These function calls return booleans based on the
    date and current state of the calendar state */
    const isRangeSelection =
      !!startDate && !!nextEndDate && startDate !== nextEndDate
    const isSingleSelection = !isRangeSelection
    const isAtUnavailableRange =
      isRangeSelection &&
      tileIsAtUnavailableRange(props) &&
      tileIsLastSelected(props)
    const isAtInsuficentRange = tileIsAtInsuficentRange(props)
    const isAtUnavailableDate =
      isSingleSelection && tileIsAtUnavailableDate(props)
    const isAtPast = tileIsAtPast(props)
    const showTooltip =
      [
        isAtUnavailableDate,
        isAtUnavailableRange,
        isAtInsuficentRange,
        isAtPast,
      ].some((condition) => Boolean(condition)) && tooltip.show

    if (!showTooltip) return null

    return (
      <div className="tooltip-wrapper" data-tip ref={tooltipRef}>
        <ReactTooltip
          effect="solid"
          multiline
          overridePosition={getOverridePosition}
        >
          <span dangerouslySetInnerHTML={{ __html: tooltip.label }} />
        </ReactTooltip>
      </div>
    )
  }

  const getTileDisabled = (props) => tileIsDisabled(props)

  const getFormatShortWeekday: DatesCustomizers['getFormatShortWeekday'] = (
    _locale,
    date,
  ) => dayjs(date).format('dd')

  return {
    getTileClassName,
    getFormatShortWeekday,
    getTileContent,
    getTileDisabled,
  }
}

export { useCalendarCustomizers }
