import dayjs from 'dayjs'
import type { Dayjs } from 'dayjs'

import type { CalendarState } from './useCalendarState'

import DatesConstants from 'constants/dates'
import AvailabilityCodes from 'constants/availableCodes'

import type { AvailabilityUnit, MinStay } from 'types/externalData'

export type DatesSentinelsCallback = (props: { date: Date }) => boolean

export type DatesSentinels = {
  tileIsAtInsuficentRange: DatesSentinelsCallback
  tileIsAtPast: DatesSentinelsCallback
  tileIsAtUnavailableDate: DatesSentinelsCallback
  tileIsAtUnavailableRange: DatesSentinelsCallback
  tileIsCheckInOnly: DatesSentinelsCallback
  tileIsCheckOutOnly: DatesSentinelsCallback
  tileIsDisabled: DatesSentinelsCallback
  tileIsFirstSelected: DatesSentinelsCallback
  tileIsLastSelected: DatesSentinelsCallback
  tileIsSelected: DatesSentinelsCallback
  tileIsUnreachable: DatesSentinelsCallback
  tileIsUnreachableInitially: DatesSentinelsCallback
  tileIsHoverable: DatesSentinelsCallback
  tileIsStayOnly: DatesSentinelsCallback
}

export type UseCalendarSentinels = (props: {
  availability: Array<AvailabilityUnit> | null | undefined
  availabilityObj: Record<string, AvailabilityUnit>
  calendarState: CalendarState
  getRange: (rangeStart: Dayjs, rangeEnd: Dayjs) => Array<Dayjs>
  endDate: CalendarState['endDate']
  isBookingFlow: boolean
  minStay?: MinStay | null
  startDate: CalendarState['startDate']
  tooltip: CalendarState['tooltip']
}) => DatesSentinels

const useCalendarSentinels: UseCalendarSentinels = (props) => {
  const {
    availability,
    availabilityObj,
    calendarState,
    getRange,
    isBookingFlow,
    minStay,
  } = props
  const { startDate, endDate, tooltip, reachableDates } = calendarState

  const tileIsSelected: DatesSentinels['tileIsSelected'] = (props) => {
    return dayjs(props.date).isBetween(startDate, endDate, 'day', '[]')
  }

  const tileIsAtPast: DatesSentinels['tileIsAtPast'] = (props) => {
    const isFirstSelected = tileIsFirstSelected(props)
    return (
      isFirstSelected &&
      [
        'CHECK_IN_AT_PAST',
        'CHECK_OUT_AT_PAST',
        'CHECK_OUT_SAME_AS_CHECK_IN',
      ].some((type) => tooltip.type === type)
    )
  }

  const tileIsLastSelected: DatesSentinels['tileIsSelected'] = (props) => {
    return dayjs(props.date).isSame(dayjs(tooltip.date))
  }

  const tileIsFirstSelected: DatesSentinels['tileIsFirstSelected'] = (
    props,
  ) => {
    return dayjs(props.date).isSame(dayjs(tooltip.date))
  }

  const tileIsAtInsuficentRange: DatesSentinels['tileIsAtInsuficentRange'] = (
    props,
  ) => {
    const isLastSelected = tileIsLastSelected(props)
    return isLastSelected && tooltip.type === 'RANGE_INSUFFICIENT'
  }

  const tileIsAtUnavailableRange: DatesSentinels['tileIsAtUnavailableRange'] = (
    props,
  ) => {
    const isBetweenRange = dayjs(props.date).isBetween(
      startDate,
      tooltip.date,
      'day',
      '[]',
    )
    return (
      isBetweenRange &&
      [
        'RANGE_INVALID',
        'CHECK_OUT_NOT_AVAILABLE',
        'CHECK_IN_ONLY',
        'CHECK_OUT_ONLY',
      ].some((type) => type === tooltip.type)
    )
  }

  const tileIsAtUnavailableDate: DatesSentinels['tileIsAtUnavailableDate'] = (
    props,
  ) => {
    const isTheUnavailableDay = dayjs(props.date).isSame(dayjs(tooltip.date))
    return (
      isTheUnavailableDay &&
      ['CHECK_IN_NOT_AVAILABLE', 'CHECK_OUT_ONLY'].some(
        (type) => type === tooltip.type,
      )
    )
  }

  const tileIsDisabled: DatesSentinels['tileIsDisabled'] = (props) => {
    const currentTileDate = dayjs(props.date)
    const yesterdayTileDate = currentTileDate.add(-1, 'd')
    const tomorrowTileDate = currentTileDate.add(1, 'd')
    const dayIsTodayOrBefore =
      currentTileDate.isSame(dayjs(), 'd') ||
      currentTileDate.isBefore(dayjs(), 'd')
    const yesterdayAvailabilityUnit =
      availabilityObj?.[yesterdayTileDate.format(DatesConstants.SPLIT_BY_DASH)]
    const currentAvailabilityUnit =
      availabilityObj?.[currentTileDate.format(DatesConstants.SPLIT_BY_DASH)]
    const tomorrowAvailabilityUnit =
      availabilityObj?.[tomorrowTileDate.format(DatesConstants.SPLIT_BY_DASH)]
    const tileIsNotSelectable =
      (currentAvailabilityUnit?.code === AvailabilityCodes.STAY_OR_CHECK_IN &&
        tomorrowAvailabilityUnit?.code === AvailabilityCodes.ONLY_CHECK_OUT) ||
      (yesterdayAvailabilityUnit?.code === AvailabilityCodes.STAY_OR_CHECK_IN &&
        currentAvailabilityUnit?.code === AvailabilityCodes.ONLY_CHECK_OUT)

    const dayIsNotAvailable = currentAvailabilityUnit
      ? [
          AvailabilityCodes['NOT_AVAILABLE'],
          AvailabilityCodes['STAY_ONLY'],
        ].some(
          (availabilityCode) =>
            availabilityCode === currentAvailabilityUnit.code,
        ) || tileIsNotSelectable
      : true

    if (!isBookingFlow && !dayIsTodayOrBefore) return false

    if (dayIsTodayOrBefore || dayIsNotAvailable) return true

    return false
  }

  const tileIsCheckInOnly: DatesSentinels['tileIsCheckInOnly'] = (props) => {
    const tileDate = dayjs(props.date)
    const dayIsCheckInOnly = [
      AvailabilityCodes.ONLY_CHECK_IN,
      AvailabilityCodes.STAY_OR_CHECK_IN,
    ].some(
      (code) =>
        availabilityObj?.[tileDate.format(DatesConstants.SPLIT_BY_DASH)]
          ?.code === code,
    )
    return dayIsCheckInOnly
  }

  const tileIsCheckOutOnly: DatesSentinels['tileIsCheckOutOnly'] = (props) => {
    const tileDate = dayjs(props.date)
    const dayIsCheckOutOnly = [
      AvailabilityCodes.ONLY_CHECK_OUT,
      AvailabilityCodes.STAY_OR_CHECK_OUT,
    ].some(
      (code) =>
        availabilityObj?.[tileDate.format(DatesConstants.SPLIT_BY_DASH)]
          ?.code === code,
    )
    return dayIsCheckOutOnly
  }

  const tileIsStayOnly: DatesSentinels['tileIsStayOnly'] = (props) => {
    const tileDate = dayjs(props.date)
    const dayIsStayOutOnly = [AvailabilityCodes.STAY_ONLY].some(
      (code) =>
        availabilityObj?.[tileDate.format(DatesConstants.SPLIT_BY_DASH)]
          ?.code === code,
    )
    return dayIsStayOutOnly
  }

  /**
   * tileIsUnreachable
   * Defines if the date cannot be used for check-out.
   * @param props - An object that contains the date of the button being evaluated.
   * @returns Whether or not the date is unreachable.
   */
  const tileIsUnreachable: DatesSentinels['tileIsUnreachable'] = (props) => {
    const isRangeSelection = !!startDate && !!endDate
    const tileDate = dayjs(props.date)
    const isDisabled = tileIsDisabled(props)

    if (isRangeSelection || isDisabled || !reachableDates) return false

    return !reachableDates?.includes(tileDate.format(DatesConstants.DEFAULT))
  }

  const tileIsUnreachableInitially: DatesSentinels['tileIsUnreachableInitially'] =
    (props) => {
      const isRangeSelection = !!startDate && !!endDate
      if (isRangeSelection) return false

      const tileDate = dayjs(props.date)
      const minStaySpecificDay =
        minStay?.find((minStayUnit) => dayjs(minStayUnit.date).isSame(tileDate))
          ?.minStay || 0
      const requiredMinStayDays = getRange(
        tileDate,
        tileDate.add(minStaySpecificDay, 'days'),
      ).slice(1)
      const dayMinStayIsInsufficient = requiredMinStayDays.some(
        (requiredMinStayDay) => {
          const availabilityCode =
            availabilityObj[
              requiredMinStayDay.format(DatesConstants.SPLIT_BY_DASH)
            ]?.code

          return availabilityCode === AvailabilityCodes['NOT_AVAILABLE']
        },
      )

      return dayMinStayIsInsufficient
    }

  const tileIsHoverable: DatesSentinels['tileIsHoverable'] = (props) => {
    if (!reachableDates) return false
    const tileDate = dayjs(props.date)
    const firstReachableDate = dayjs(reachableDates[0])
    const lastReachableDate = dayjs(reachableDates[reachableDates.length - 1])
    const hoverableRange = getRange(firstReachableDate, lastReachableDate).map(
      (reachableDate) => reachableDate.format(DatesConstants.DEFAULT),
    )
    return hoverableRange.includes(tileDate.format(DatesConstants.DEFAULT))
  }

  return {
    tileIsAtInsuficentRange,
    tileIsAtPast,
    tileIsAtUnavailableDate,
    tileIsAtUnavailableRange,
    tileIsCheckInOnly,
    tileIsCheckOutOnly,
    tileIsDisabled,
    tileIsFirstSelected,
    tileIsHoverable,
    tileIsLastSelected,
    tileIsSelected,
    tileIsStayOnly,
    tileIsUnreachable,
    tileIsUnreachableInitially,
  }
}

export { useCalendarSentinels }
