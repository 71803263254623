import dayjs from 'dayjs'
import { useReducer } from 'react'

export type CalendarState = {
  activeStartDate: Date
  calendarKey: string
  endDate: string
  isValidCheckIn: boolean
  stayOnlyInfo: {
    show: boolean
    label: string
  }
  reachableDates: Array<string> | null
  startDate: string
  tooltip: {
    show: boolean
    type: string
    label: string
    date: string
  }
  visibleEndDate: string
  visibleStartDate: string
}

export enum CalendarActions {
  INVALIDATE_RANGE_SELECTION = 'INVALIDATE_RANGE_SELECTION',
  INVALIDATE_SINGLE_SELECTION = 'INVALIDATE_SINGLE_SELECTION',
  RESET_RANGE_SELECTION = 'RESET_RANGE_SELECTION',
  SET_ACTIVE_START_DATE = 'SET_ACTIVE_START_DATE',
  SET_RANGE_SELECTION = 'SET_RANGE_SELECTION',
  SET_REACHABLE_DATES = 'SET_REACHABLE_DATES',
  SET_START_DATE = 'SET_START_DATE',
}

export type ActionLike = {
  type: CalendarActions
  payload: Partial<CalendarState>
}

type useCalendarStateProps = {
  initialStartDate: string
  initialEndDate: string
  isSearchPage: boolean
  isBookingFlow: boolean
}

const DAYS_TO_SWITCH_NEXT_MONTH = 5

const useCalendarState = (props: useCalendarStateProps) => {
  const { initialStartDate, initialEndDate, isSearchPage, isBookingFlow } =
    props

  const initialState: CalendarState = {
    activeStartDate: dayjs().toDate(),
    calendarKey: '',
    endDate: '',
    isValidCheckIn: true,
    stayOnlyInfo: {
      show: false,
      label: '',
    },
    startDate: '',
    tooltip: {
      show: false,
      type: '',
      label: '',
      date: '',
    },
    reachableDates: null,
    visibleEndDate: '',
    visibleStartDate: '',
  } as const

  const DatesReducer = (
    state: CalendarState,
    action: ActionLike,
  ): CalendarState => {
    const { type, payload } = action
    const {
      INVALIDATE_RANGE_SELECTION,
      INVALIDATE_SINGLE_SELECTION,
      RESET_RANGE_SELECTION,
      SET_ACTIVE_START_DATE,
      SET_RANGE_SELECTION,
      SET_REACHABLE_DATES,
      SET_START_DATE,
    } = CalendarActions
    switch (type) {
      case SET_START_DATE: {
        const { startDate = initialState.startDate } = payload
        return {
          ...state,
          startDate,
        }
      }

      case SET_ACTIVE_START_DATE: {
        const { activeStartDate = initialState.activeStartDate } = payload
        return {
          ...state,
          activeStartDate,
        }
      }

      case SET_RANGE_SELECTION: {
        const {
          endDate = initialState.endDate,
          stayOnlyInfo = initialState.stayOnlyInfo,
          startDate = initialState.startDate,
          visibleEndDate = initialState.visibleEndDate,
          visibleStartDate = initialState.visibleStartDate,
        } = payload
        return {
          ...state,
          startDate,
          endDate,
          visibleStartDate,
          visibleEndDate,
          tooltip: {
            show: false,
            type: '',
            label: '',
            date: '',
          },
          isValidCheckIn: true,
          stayOnlyInfo,
        }
      }

      case INVALIDATE_SINGLE_SELECTION: {
        const {
          stayOnlyInfo = initialState.stayOnlyInfo,
          tooltip = initialState.tooltip,
          calendarKey = initialState.calendarKey,
        } = payload
        return {
          ...state,
          tooltip,
          calendarKey,
          isValidCheckIn: false,
          stayOnlyInfo,
          visibleStartDate: initialState.visibleStartDate,
          visibleEndDate: initialState.visibleEndDate,
          startDate: initialState.startDate,
          endDate: initialState.endDate,
        }
      }

      case INVALIDATE_RANGE_SELECTION: {
        const {
          tooltip = initialState.tooltip,
          startDate = initialState.startDate,
          endDate = initialState.endDate,
          stayOnlyInfo = initialState.stayOnlyInfo,
          visibleStartDate = state.visibleStartDate,
          visibleEndDate = state.visibleEndDate,
          reachableDates = state.reachableDates,
        } = payload

        return {
          ...state,
          tooltip,
          startDate,
          endDate,
          stayOnlyInfo,
          visibleStartDate,
          visibleEndDate,
          reachableDates,
        }
      }

      case SET_REACHABLE_DATES: {
        const {
          reachableDates = state.reachableDates,
          stayOnlyInfo = state.stayOnlyInfo,
        } = payload
        return {
          ...state,
          reachableDates,
          stayOnlyInfo,
        }
      }

      case RESET_RANGE_SELECTION: {
        const { calendarKey = state.calendarKey } = payload
        return {
          ...initialState,
          activeStartDate: state.activeStartDate,
          calendarKey,
        }
      }
      default: {
        return state
      }
    }
  }

  const lazyInit = (baseInitialState) => {
    const daysLeft = dayjs().endOf('month').diff(dayjs(), 'day')
    const newActiveStartDate =
      daysLeft > DAYS_TO_SWITCH_NEXT_MONTH || !isBookingFlow
        ? dayjs()
        : dayjs()
            .month(dayjs().month() + 1)
            .date(1)
    const activeStartDate = initialStartDate
      ? dayjs(initialStartDate).toDate()
      : newActiveStartDate.toDate()

    return {
      ...baseInitialState,
      startDate: initialStartDate,
      endDate: initialEndDate,
      visibleStartDate: isSearchPage
        ? initialStartDate
        : initialState.visibleStartDate,
      visibleEndDate: isSearchPage
        ? initialEndDate
        : initialState.visibleEndDate,
      activeStartDate,
    }
  }

  return useReducer(DatesReducer, initialState, lazyInit)
}

export { useCalendarState }
