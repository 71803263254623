import React from 'react'

import style from './Radio.module.scss'

type RadioProps = React.InputHTMLAttributes<HTMLInputElement> & {
  label: string
  dataTestId: string
  onValueChange: (value: string) => any
}

const Radio = ({
  label,
  name,
  id,
  value,
  disabled,
  checked,
  defaultChecked,
  onValueChange,
  dataTestId,
}: RadioProps) => {
  return (
    <div className={style.radio}>
      <input
        checked={checked}
        data-testid={dataTestId}
        defaultChecked={defaultChecked}
        disabled={disabled}
        id={id}
        name={name}
        onChange={(e) => onValueChange(e.target.value)}
        type="radio"
        value={value}
      />
      <label htmlFor={id}>{label}</label>
    </div>
  )
}

export default Radio
